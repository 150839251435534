import { template as template_fd3b402b32034fe6874352a7df916e9a } from "@ember/template-compiler";
const FKControlMenuContainer = template_fd3b402b32034fe6874352a7df916e9a(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
